<template>
  <slot :classes="classes"></slot>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { SiteColor } from '#graphql-operations'
import { getButtonClasses, SiteColorStyle } from '~/helpers/siteColor'

const props = defineProps({
  color: String as PropType<SiteColor>,
  style: String as PropType<SiteColorStyle>,
  green: Boolean,
  lime: Boolean,
  berry: Boolean,
  pink: Boolean,
  cyan: Boolean,
  blue: Boolean,
  orange: Boolean,
  inverted: Boolean,
  outlined: Boolean,
  small: Boolean,
})

const style = computed(() => {
  if (props.style) {
    return props.style
  }
  if (props.inverted) {
    return 'inverted'
  } else if (props.outlined) {
    return 'outlined'
  }
  return 'filled'
})

const siteColor = computed(() => {
  if (props.color) {
    return props.color
  }
  if (props.lime) {
    return SiteColor.LIME
  } else if (props.green) {
    return SiteColor.GREEN
  } else if (props.berry) {
    return SiteColor.BERRY
  } else if (props.pink) {
    return SiteColor.PINK
  } else if (props.cyan) {
    return SiteColor.CYAN
  } else if (props.blue) {
    return SiteColor.BLUE
  } else if (props.orange) {
    return SiteColor.ORANGE
  }
  return SiteColor.GREEN_ALT
})

const classes = computed(() => {
  return (
    'button ' +
    getButtonClasses(siteColor.value, style.value) +
    (props.small ? ' is-narrow' : '')
  )
})
</script>
