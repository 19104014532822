import { SiteColor, SiteColorFragment } from '#graphql-operations'

export type SiteColorStyle = 'filled' | 'inverted' | 'outlined'
export type SiteColorStateClasses = [base: string, hover: string]
export type SiteColorClasses = [
  base: SiteColorStateClasses,
  inverted: SiteColorStateClasses,
  outlined: SiteColorStateClasses,
]

/**
 * The mapping of site colors to appropriate Tailwind classes.
 *
 * Each mapping array contains three items (as described by SiteColorClasses).
 * The three items contain two items: The normal classes and then the hover classes.
 */
const MAPPING: Record<SiteColor, SiteColorClasses> = {
  [SiteColor.BERRY]: [
    // Filled.
    ['bg-berry text-white', 'hover:bg-berry-light group-hover:bg-berry-light'],
    // Inverted.
    [
      'text-berry',
      'hover:bg-berry hover:text-white group-hover:bg-berry group-hover:text-white',
    ],
    // Outlined.
    ['!text-berry', 'hover:bg-white hover:!text-berry-light'],
  ],
  [SiteColor.ORANGE]: [
    // Filled.
    [
      'bg-orange text-white',
      'hover:bg-orange-light group-hover:bg-orange-light',
    ],
    // Inverted.
    [
      'text-orange',
      'hover:bg-orange hover:text-white group-hover:bg-orange group-hover:text-white',
    ],
    // Outlined.
    ['text-orange', 'hover:bg-orange/5'],
  ],
  [SiteColor.PINK]: [
    // Filled.
    ['bg-pink text-white', 'hover:bg-pink-light group-hover:bg-pink-light'],
    // Inverted.
    ['text-pink', 'hover:bg-pink hover:text-white'],
    // Outlined.
    ['text-pink', 'hover:bg-pink/5'],
  ],
  [SiteColor.BLUE_GRADIENT]: [
    // Filled.
    [
      'bg-gradient-to-r from-blue to-transparent bg-cyan bg-no-repeat text-white',
      'hover:bg-blue group-hover:bg-blue',
    ],
    // Inverted.
    ['text-blue', 'hover:bg-blue hover:text-white'],
    // Outlined.
    ['text-blue', 'hover:bg-blue/5'],
  ],
  [SiteColor.CYAN]: [
    // Filled.
    ['bg-cyan text-white', 'hover:bg-cyan-light group-hover:bg-cyan-light'],
    // Inverted.
    ['text-cyan', 'hover:bg-cyan hover:text-white'],
    // Outlined.
    ['text-cyan', 'hover:bg-cyan/5'],
  ],
  [SiteColor.BLUE]: [
    // Filled.
    ['bg-blue text-white', 'hover:bg-blue-light group-hover:bg-blue-light'],
    // Inverted.
    ['text-blue', 'hover:bg-blue hover:text-white'],
    // Outlined.
    ['text-blue', 'hover:bg-blue/5'],
  ],
  [SiteColor.CYAN_LIGHT]: [
    // Filled.
    [
      'bg-cyan-light text-white',
      'hover:bg-cyan-lighter hover:text-blue group-hover:bg-cyan-lighter',
    ],
    // Inverted.
    ['text-cyan-light', 'hover:bg-cyan-light hover:text-white'],
    // Outlined.
    ['text-cyan-light', 'hover:bg-cyan-light/5'],
  ],
  [SiteColor.CYAN_LIGHTER]: [
    // Filled.
    [
      'bg-cyan-lighter text-blue',
      'hover:bg-cyan-light hover:text-white group-hover:bg-cyan-light',
    ],
    // Inverted.
    ['text-cyan', 'hover:bg-cyan-lighter hover:text-blue'],
    // Outlined.
    ['text-cyan', 'hover:bg-cyan-lighter/5'],
  ],
  [SiteColor.GREEN]: [
    // Filled.
    ['bg-green text-white', 'hover:bg-green-alt group-hover:bg-green-alt'],
    // Inverted.
    ['text-green', 'hover:bg-green hover:text-white'],
    // Outlined.
    ['text-green', 'hover:bg-green/5'],
  ],
  [SiteColor.LIME]: [
    // Filled.
    ['bg-lime text-white', 'hover:bg-green-alt group-hover:bg-green-alt'],
    // Inverted.
    ['text-lime', 'hover:bg-lime hover:text-white'],
    // Outlined.
    ['text-lime', 'hover:bg-lime/5'],
  ],
  [SiteColor.LIME_LIGHT]: [
    [
      'bg-lime-light text-green',
      'hover:bg-lime hover:text-white group-hover:bg-lime',
    ],
    ['text-lime-light', 'hover:bg-lime-light hover:text-white'],
    ['text-lime-light', 'hover:bg-lime-light/5'],
  ],
  [SiteColor.GREEN_ALT]: [
    // Filled.
    ['bg-green-alt text-white', 'hover:bg-lime group-hover:bg-lime'],
    // Inverted.
    ['text-green-alt', 'hover:bg-green-alt hover:text-white'],
    // Outlined.
    ['text-green-alt', 'hover:bg-green-alt/5'],
  ],
}

/**
 * Mapping of complementary colors.
 *
 * This is used for example on the teaser boxes that have a background color
 * and a button at the bottom.
 */
const COMPLEMENTARY_MAPPING: Record<SiteColor, SiteColor> = {
  [SiteColor.BERRY]: SiteColor.PINK,
  [SiteColor.PINK]: SiteColor.BERRY,
  [SiteColor.BLUE_GRADIENT]: SiteColor.CYAN,
  [SiteColor.BLUE]: SiteColor.CYAN,
  [SiteColor.CYAN_LIGHT]: SiteColor.BLUE,
  [SiteColor.CYAN_LIGHTER]: SiteColor.CYAN,
  [SiteColor.CYAN]: SiteColor.BLUE,
  [SiteColor.GREEN]: SiteColor.LIME,
  [SiteColor.LIME]: SiteColor.GREEN,
  [SiteColor.LIME_LIGHT]: SiteColor.LIME,
  [SiteColor.GREEN_ALT]: SiteColor.GREEN,
  [SiteColor.ORANGE]: SiteColor.PINK,
}

export function getComplementaryColor(
  v: SiteColor | SiteColorFragment | undefined,
): SiteColor | undefined {
  const key = typeof v === 'string' ? v : v?.color
  return key ? COMPLEMENTARY_MAPPING[key] : undefined
}

/**
 * Get the classes for the given site color.
 *
 * If the key is invalid a default class is returned.
 */
export function getBoxClasses(
  v: SiteColor | SiteColorFragment | undefined,
): SiteColorClasses {
  const key = typeof v === 'string' ? v : v?.color
  if (key && MAPPING[key]) {
    return MAPPING[key]
  }

  return MAPPING.GREEN_ALT
}

/**
 * Get the classes for buttons.
 */
export function getButtonClasses(
  v: SiteColor | SiteColorFragment | undefined,
  style: SiteColorStyle = 'filled',
): string {
  const key = typeof v === 'string' ? v : v?.color
  const baseColor = getBoxClasses(key)

  if (style === 'inverted') {
    return baseColor[1].join(' ') + ' bg-white'
  } else if (style === 'outlined') {
    return baseColor[2].join(' ') + ' bg-white border-2 border-current'
  }

  return baseColor[0].join(' ')
}
